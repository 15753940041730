.headline {
	display: inline;

	h2 {
		margin: 0;
		font-size: 3.125rem;
		line-height: 4.0625rem;
		font-weight: $normalFontWeight;
		display: inline;
	}

	h2.gradient span {
		background: linear-gradient(
			0deg,
			rgba(255, 0, 255, 0) 0%,
			rgba(255, 0, 255, 0) 10%,
			rgba($highlightColor, 1) 10%,
			rgba($highlightColor, 1) 30%,
			rgba(255, 0, 255, 0) 30%
		);
	}

	h2.padding span {
		padding: 0 0.25em 0 0.1em;
		box-decoration-break: clone;
		-webkit-box-decoration-break: clone;
	}

	@media screen and (max-width: $tabletWidth) {
		h2 {
			font-size: 2rem;
			line-height: 2.5rem;
		}
	}

	@media screen and (max-width: $mobileWidth) {
		h2 {
			font-size: 1.25rem;
			line-height: 1.625rem;
		}
	}
}
