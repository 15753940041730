.hero {
	height: calc(100vh - 7.8125rem);
	max-height: calc(49.6875rem + 7.8125rem);
	background-color: $defaultBackgroundColor;
	background-image: url('../assets/hero.jpg');
	background-size: cover;
	position: relative;
	background-position: 50% 50%;

	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.hero-content {
		height: 100%;
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		display: flex;
		box-sizing: border-box;
		padding: 3.5rem $defaultPadding;

		& > div {
			flex: 1;
			display: flex;
		}
	}

	h1 {
		font-size: 7.5rem;
		line-height: 7rem;
		margin: 0;
		font-weight: $normalFontWeight;
		align-self: center;
		flex: 1;
		max-width: 12.5rem;
	}

	p {
		margin: 0;
		font-size: 1.5rem;
		line-height: 2rem;
		flex: 1;
		max-width: 50rem;
		align-self: flex-end;
		-webkit-hyphens: auto;
		-moz-hyphens: auto;
		-ms-hyphens: auto;
		hyphens: auto;
	}

	@media screen and (max-width: $tabletWidth) {
		h1 {
			font-size: 6rem;
			line-height: 6rem;
		}
	}

	@media screen and (max-width: $tabletWidth) {
		.hero-content {
			padding: 1.875rem $defaultMobilePadding;
		}

		p {
			font-size: 1.25rem;
			line-height: 1.5rem;
		}
	}

	@media screen and (max-width: $mobileWidth) {
		height: calc(85vh - 7.0625rem);

		h1 {
			font-size: 4.5rem;
			line-height: 4.5rem;
		}

		p {
			position: absolute;
			left: 1rem;
			right: 1rem;
			order: 2;
			flex: 3;
			font-size: 1rem;
			line-height: 1.375rem;
		}
	}
}
