.references {
	background-color: $greyBackgroundColor;
	padding: 9rem $defaultPadding;
	position: relative;
	display: flex;
	justify-content: center;

	.link {
		position: absolute;
		top: 9rem;
		left: $defaultPadding;
		text-decoration: underline;
	}

	.icons {
		max-width: $maxContentWidth;
		flex-wrap: wrap;
		justify-content: space-between;
		display: flex;
		gap: 3rem 4.5rem;

		img {
			max-height: 3rem;
			height: 100%;
		}
	}

	@media screen and (max-width: 1140px) {
		flex-direction: column;

		.link {
			position: relative;
			margin-bottom: 3rem;
			left: 0;
			top: 0;
		}
	}

	@media screen and (max-width: $tabletWidth) {
		padding: 2rem $defaultMobilePadding 10rem;

		.icons {
			justify-content: center;
			gap: 6vw 8vw;
		}
	}

	@media screen and (max-width: $mobileWidth) {
		.icons img {
			max-height: 2rem;
		}
	}
}
