@use '../styles/variables.scss';

html {
	scroll-behavior: smooth;
}

body {
	font-family: 'Suisse';
	font-style: normal;
	font-weight: 400;
	font-weight: $normalFontWeight;
	font-size: $defaultFontSize;
	line-height: $defaultLineHeight;
	color: $defaultFontColor;
	box-sizing: border-box;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	margin: 0 auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transition: $defaultTransition;
	background-color: $highlightColor;
	margin-bottom: 18rem;

	::selection {
		background: $highlightColor;
	}

	@media (max-width: $mobileWidth) {
		font-size: 1rem;
		line-height: 1.375rem;
		margin-bottom: 8rem;
	}
}

*::-webkit-scrollbar {
	display: none;
}
