.solution {
	display: flex;

	.info {
		flex: 0 0 50%;
		background-color: $greyBackgroundColor;
		padding: 4rem;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;

		.title {
			line-height: 1rem;
			text-decoration: underline;
			width: fit-content;
		}

		.text {
			margin: 2rem 0;
			max-width: $textContainerMaxWidth;
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		margin-top: auto;
	}

	@media screen and (max-width: 1020px) {
		flex-direction: column;
	}

	@media screen and (max-width: $mobileWidth) {
		.info {
			padding: 2rem $defaultMobilePadding;

			.text {
				padding-bottom: 4.625rem;
			}
		}
	}
}
