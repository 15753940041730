.feedback {
	padding: 7rem $defaultPadding;
	background-color: $defaultBackgroundColor;
	display: flex;
	justify-content: center;
	position: relative;

	.link {
		position: absolute;
		left: $defaultPadding;
		top: 7.5rem;
		text-decoration: underline;
	}

	.content {
		max-width: $maxContentWidth;
		width: 100%;
		-webkit-hyphens: auto;
		-moz-hyphens: auto;
		-ms-hyphens: auto;
		hyphens: auto;

		.quote {
			font-size: 3.125rem;
			line-height: 4.0625rem;
			display: inline;
			-webkit-hyphens: auto;
			-moz-hyphens: auto;
			-ms-hyphens: auto;
			hyphens: auto;
		}

		.source {
			margin-top: 3rem;
			font-size: 1.125rem;
			line-height: 1.375rem;
			text-align: right;
		}
	}

	@media screen and (max-width: 1240px) {
		flex-direction: column;

		.link {
			top: 0;
			left: 0;
			position: relative;
			margin-bottom: 1.5rem;
		}
	}

	@media screen and (max-width: $tabletWidth) {
		padding: 2rem $defaultMobilePadding 4rem;

		.content {
			.quote {
				font-size: 2rem;
				line-height: 2.5rem;
			}

			.source {
				font-size: 1.125rem;
				line-height: 1.25rem;
			}
		}
	}

	@media screen and (max-width: $mobileWidth) {
		padding: 2rem $defaultMobilePadding 4rem;

		flex-direction: column;
		align-items: flex-start;

		.content {
			.quote {
				font-size: 1.25rem;
				line-height: 1.625rem;
			}

			.source {
				max-width: 45vw;
				font-size: 0.875rem;
				line-height: 1.125rem;
				margin-top: 1.5rem;
				margin-left: auto;
			}
		}
	}
}
