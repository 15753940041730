// COLORS
$defaultFontColor: #000;
$defaultBackgroundColor: #fff;
$highlightColor: #00ff4a;
$greyBackgroundColor: #f6f6f6;

// DIMENSIONS
$mobileWidth: 30rem; // 480px
$tabletWidth: 48rem; // 768px
$maxContentWidth: 48rem; // 768px

// FONT
$normalFontWeight: 400;
$defaultFontSize: 1.125rem; // 18px
$defaultLineHeight: 1.5rem; // 24px
$textContainerMaxWidth: 68rem; // 1.088px

// STYLING
$defaultTransition: 0.2s all ease-in-out;
$defaultGap: 6.5625rem; // 105px
$defaultPadding: 5rem; // 80px
$defaultMobilePadding: 1rem; // 16px

// TODO: all hover + accessibility
