.services {
	background-color: $defaultBackgroundColor;
	padding: 9.125rem $defaultPadding 2rem;

	p {
		font-size: 3.125rem;
		line-height: 4.0625rem;
		margin: 0;
		margin-bottom: 5rem;
		-webkit-hyphens: auto;
		-moz-hyphens: auto;
		-ms-hyphens: auto;
		hyphens: auto;
	}

	.link {
		text-decoration: underline;
	}

	@media screen and (max-width: $tabletWidth) {
		padding: 4.5rem $defaultMobilePadding 1rem;

		p {
			font-size: 2rem;
			line-height: 2.5rem;
		}
	}

	@media screen and (max-width: $mobileWidth) {
		p {
			font-size: 1.25rem;
			line-height: 1.625rem;
		}
	}
}
