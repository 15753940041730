.about {
	padding: 3.75rem $defaultPadding 7.75rem;
	background-color: $defaultBackgroundColor;

	.content {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		position: relative;
		width: 100%;

		p {
			font-size: 3.125rem;
			line-height: 4.0625rem;
			width: 700px;
			min-width: 40%;
			max-width: 100%;
			margin: 0;
			-webkit-hyphens: auto;
			-moz-hyphens: auto;
			-ms-hyphens: auto;
			hyphens: auto;
		}

		.link {
			position: absolute;
			left: 0;
			top: 0.5rem;
			text-decoration: underline;
		}
	}

	@media screen and (max-width: 1020px) {
		.content {
			flex-direction: column;

			.link {
				position: relative;
				margin-bottom: 0.75rem;
			}
		}
	}

	@media screen and (max-width: $tabletWidth) {
		padding: 2rem $defaultMobilePadding 1.75rem;

		.content p {
			font-size: 2rem;
			line-height: 2.5rem;
		}
	}

	@media screen and (max-width: $mobileWidth) {
		.content p {
			font-size: 1.125rem;
			line-height: 1.625rem;
		}
	}
}
