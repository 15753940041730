@import './variables';
@import './app';
@import './about';
@import './caption';
@import './carousel';
@import './filler';
@import './feedback';
@import './footer';
@import './fullscreen';
@import './headline';
@import './hero';
@import './imprint';
@import './navbar';
@import './profile';
@import './services';
@import './solution';
@import './references';

@font-face {
	font-family: 'Suisse';
	src: local('Suisse'), url('../fonts/SuisseIntl-Regular-WebM.ttf') format('truetype');
	font-weight: 400;
}
