footer {
	display: flex;
	background-color: $defaultBackgroundColor;
	padding: 8.5rem $defaultPadding 4rem;
	justify-content: space-between;
	align-items: flex-end;
	font-size: 1.125rem;
	line-height: 1.5rem;

	.contact {
		display: flex;
		flex-direction: column;
	}

	.info {
		.address {
			display: flex;
			flex-direction: column;

			#mobile {
				display: none;
			}
		}
	}

	.info,
	.legal {
		display: flex;
		align-items: flex-end;
		gap: 3.5rem;
	}

	.lang {
		display: flex;

		& > * {
			cursor: pointer;

			&:not(:last-child):after {
				content: '|';
				font-size: 1.25rem;
				padding: 0 0.5rem;
			}
		}
	}

	a {
		text-decoration: none;
		color: $defaultFontColor;
	}

	button:hover,
	a:hover {
		text-decoration: underline;
	}

	button {
		all: unset;
	}

	@media screen and (max-width: 1020px) {
		flex-direction: column;
		justify-content: flex-start;
		font-size: 1rem;
		line-height: 1.25rem;

		.info {
			align-items: flex-start;

			& > * {
				flex: 1;
			}

			.address {
				#desktop {
					display: none;
				}
				#mobile {
					display: inline;
				}
			}
		}

		.info,
		.legal {
			gap: 1rem;
			width: 100%;
		}

		.lang {
			margin-left: auto;
			margin-top: 4.5rem;
		}
	}

	@media screen and (max-width: $tabletWidth) {
		padding: 5.5rem $defaultMobilePadding 1rem;
	}
}
