.imprint,
.policy {
	background-color: $greyBackgroundColor;
	padding: 7.5rem $defaultPadding 12.5rem;
	display: flex;
	gap: 6.5rem;

	.link {
		text-decoration: underline;
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.text {
		max-width: $maxContentWidth;

		div {
			margin: 0;

			h4 {
				text-decoration: underline;
				font-weight: 400;
				margin: 0;
			}

			&:not(:last-child) {
				padding-bottom: 2rem;
			}
		}

		a {
			color: #000;
			transition: color 0.2s ease-in-out;

			&:hover {
				color: $highlightColor;
			}
		}
	}

	@media screen and (max-width: 1020px) {
		flex-direction: column;
		gap: 3rem;
	}

	@media screen and (max-width: $tabletWidth) {
		padding: 3rem $defaultMobilePadding 5rem;
	}

	@media screen and (max-width: $mobileWidth) {
		.link {
			font-size: 1rem;
			line-height: 1.375rem;
		}

		.text p {
			font-size: 0.875rem;
			line-height: 1.125rem;

			&:not(:last-child) {
				padding-bottom: 1rem;
			}
		}
	}
}
