nav {
	display: flex;
	background-color: $defaultBackgroundColor;
	justify-content: space-between;
	align-items: center;
	padding: 3rem $defaultPadding 1.5rem;

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		display: flex;
		gap: 2.5rem;

		li {
			margin: 0;
			cursor: pointer;
			margin-top: 1rem;
			font-size: 1.125rem;
			line-height: 1.25rem;

			a {
				text-decoration: none;
				color: $defaultFontColor;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	@media screen and (max-width: $tabletWidth) {
		padding: 1.875rem $defaultMobilePadding;
	}

	@media screen and (max-width: $mobileWidth) {
		ul {
			gap: 1.125rem;
			padding-bottom: 0.125rem;

			li {
				font-size: 0.875rem;
				line-height: 1.125rem;
			}
		}
	}
}

.logo {
	@media screen and (max-width: $mobileWidth) {
		max-width: 7.5rem;
	}
}
